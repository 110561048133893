<template>
	<div>
		<div v-if="useFormFor === 'mobile'">
			<div class="content-header">
				<b-row class="mt-1">
					<b-col cols="9">
						<div>
							<span class="tab-month" :class="{ 'active-view-active': activeView === 'month' }" @click="changeActiveView('month')">
								<b-icon icon="calendar4" />
							</span>
							<span class="tab-week" :class="{ 'active-view-active': activeView === 'day' }" @click="changeActiveView('day')">
								{{ FormMSG(148, 'Day') }}
							</span>
							<span v-show="showTabToday" class="tab-today" @click="handleClickToday">
								{{ FormMSG(149, 'Today') }}
							</span>
						</div>
					</b-col>
				</b-row>
				<div class="calendar mt-4">
					<b-row v-show="activeView === 'month'">
						<b-col>
							<Calendar
								v-model="allowedEntryOutOfDateAttributes[0].dates"
								:attributes="allowedEntryOutOfDateAttributes"
								:locale="getCurLangStr"
								:from-page="dateToShow"
								:columns="1"
								:available-dates="allAvailableDates"
								is-expanded
								@dayclick="calDateSelected"
								@update:from-page="calPageUpdated"
							/>
						</b-col>
					</b-row>
					<calendar-work
						:show-status="!isFilmSingle"
						v-show="activeView === 'day'"
						:cur-day="curDay"
						@calendar-work:change-date="calDateSelected"
						@calendar-work:from-page="calPageUpdated"
					/>
				</div>
			</div>
			<div class="divider-line" />
			<div class="content-body">
				<b-collapse :visible="!curDay.strEndNotSpecified">
					<b-collapse :visible="!curDay.stageDayUsed">
						<div v-if="!curDay.strEndNotSpecified">
							<b-row v-if="!curDay.stageDayUsed">
								<b-col>
									<h2 class="fw-600">{{ FormMSG(150, 'Enter Time') }}</h2>
								</b-col>
							</b-row>
						</div>
						<div v-if="disableSave" class="alert alert-warning">
							{{ FormMSG(11, 'A timesheet has already been submitted for this day. Please choose another date.') }}
						</div>
						<div
							class="d-flex align-items-center alert alert-warning"
							role="alert"
							v-if="
								multipleSelectionDate === false &&
								selectedDateFromMonth === true &&
								allowTimesheetEntryOutOfContract === false &&
								curDay.isAvailable === false &&
								screenUsedTheForm === isTimeCard
							"
						>
							<div class="text-center pr-1">
								<component :is="getLucideIcon('AlertTriangle')" color="#CD9A16" :size="22" />
							</div>
							<div class="ml-3" style="font-size: 1rem">{{ FormMSG(701, `You don't have any contract valid on this selected date.`) }}</div>
						</div>
						<div
							v-if="
								multipleSelectionDate === false &&
								allowTimesheetEntryOutOfContract === true &&
								curDay.isAvailable === false &&
								screenUsedTheForm === isTimeCard
							"
							class="d-flex align-items-center alert alert-warning"
							role="alert"
						>
							<div class="text-center pr-1">
								<component :is="getLucideIcon('CheckCircle')" color="#CD9A16" :size="22" />
							</div>
							<div class="ml-2" style="font-size: 1rem">
								{{
									FormMSG(
										702,
										`Even you don't have any contract valid on this selected date. The "app" let you enter your work details anyway.`
									)
								}}
							</div>
						</div>
						<div v-if="!curDay.strEndNotSpecified">
							<b-row v-if="!curDay.stageDayUsed" class="mt-3">
								<b-col>
									<StartEndTimeOverRange
										:start-backward-day="1"
										:start-forward-day="0"
										:end-backward-day="0"
										:end-forward-day="1"
										hide-start-date
										:value="{
											start: curDay.strTime,
											end: curDay.endTime,
											date: curDay.date
										}"
										:start-label="FormMSG(209, 'Start Time')"
										:end-label="FormMSG(210, 'End Time')"
										:disable-save="disableSave"
										:hide-label-date="days.length > 1"
										@change-range="updateStrEndTimes"
										:days="days"
										:multipleSelectionDate="multipleSelectionDate"
									/>
								</b-col>
							</b-row>
						</div>
						<div v-if="!curDay.strEndNotSpecified">
							<b-row v-if="!curDay.stageDayUsed" class="mt-3">
								<b-col>
									<div class="total">
										<div class="d-flex flex-row justify-content-center align-items-center">
											<b-col cols="4" class="text-center p-0">
												<h2 class="total-hours fw-600">{{ curDay.hhmm === '' ? '--:--' : getHhMmString(curDay.hhmm) }}</h2>
												<div style="font-size: 14px">{{ FormMSG(202, 'Worked Hours') }}</div>
											</b-col>
											<b-col v-if="canSeeSalary" cols="4" class="text-center p-0">
												<h2 class="total-hours fw-600">{{ getCurrency(curDay.salary) }}</h2>
												<div style="font-size: 14px">{{ FormMSG(221, 'Salary') }}</div>
											</b-col>
											<b-col cols="4" class="text-center p-0">
												<h2 class="total-hours fw-600">{{ curDay.kgCoTwo === 0 ? '--' : getCO2(curDay.kgCoTwo) }}</h2>
												<div style="font-size: 14px">CO<sub>2</sub></div>
											</b-col>
										</div>
									</div>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
				</b-collapse>
				<div class="accordion" style="margin-bottom: 40px" role="tablist">
					<b-row v-if="curDay.id !== 0" style="margin-bottom: 24px; margin-top: 24px">
						<b-col>
							<div
								v-b-toggle.accordion-1
								class="show-details d-flex flex-row"
								style="color: #225cbd; font-size: 16px; font-weight: 500; width: 100%"
								@click="handleClickAccordion('details')"
							>
								<!-- :style="`margin-bottom: ${accordionActive === 'details' ? '15px' : '0'}`" -->
								<div style="width: 50%">
									<div class="wrap-status d-flex justify-content-start" v-if="!isFilmSingle">
										<div :class="`status ${curDay.statusClass}`" style="font-size: 0.815rem">
											{{ curDay.validatedStatus }}
										</div>
									</div>
								</div>
								<div class="d-flex justify-content-end" style="width: 50%">
									<div
										class="d-flex flex-row justify-content-end align-items-center"
										style="width: 100%"
										v-if="accordionActive !== 'details'"
									>
										<div style="font-size: 14px">{{ FormMSG(154, 'Show Details') }}</div>
										<div class="px-1"><component :is="getLucideIcon('ChevronDown')" :size="20" /></div>
									</div>
									<div class="d-flex flex-row justify-content-end align-items-center" v-else>
										<div style="font-size: 14px">{{ FormMSG(155, 'Hide Details') }}</div>
										<div class="px-1"><component :is="getLucideIcon('ChevronUp')" :size="20" /></div>
									</div>
								</div>
							</div>
							<b-collapse id="accordion-1" accordion="my-accordion">
								<div class="content-detailsMyTimesheet-mobile">
									<b-row>
										<b-col>
											<div class="details-timesheet">
												<ul>
													<li>
														<div class="column-1">{{ FormMSG(510, 'Date') }}</div>
														<div class="column-2">
															{{ rendDate(curDay.date) }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(520, 'Start') }}</div>
														<div class="column-2">{{ getHours(curDay.strTime) }}</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(530, 'End') }}</div>
														<div class="column-2">{{ getHours(curDay.endTime) }}</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(540, 'Type') }}</div>
														<div class="column-2" v-if="canSeeSalary">
															{{ getFieldValueByShowGridDetails(true, curDay.dayType, curDay.salaryBase) }}
														</div>
														<div class="column-2" v-else>
															{{ getFieldValueByShowGridDetails(false, curDay.dayType) }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(1690, 'Overtime') }}</div>
														<div class="column-2" v-if="canSeeSalary">
															{{ curDay.hhmmOvt + ' | ' + getCurrency(curDay.salaryOvertime) }}
														</div>
														<div class="column-2" v-else>
															{{ curDay.hhmmOvt }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(1700, 'Overtime 1') }}</div>
														<div class="column-2" v-if="canSeeSalary">
															{{ curDay.hhmmOvt1 + ' | ' + getCurrency(curDay.salaryOvertime1) }}
														</div>
														<div class="column-2" v-else>
															{{ curDay.hhmmOvt1 }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(1710, 'Overtime 2') }}</div>
														<div class="column-2" v-if="canSeeSalary">
															{{ curDay.hhmmOvt2 + ' | ' + getCurrency(curDay.salaryOvertime2) }}
														</div>
														<div class="column-2" v-else>
															{{ curDay.hhmmOvt2 }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(1580, 'Transport') }}</div>
														<div class="column-2" v-if="canSeeSalary">
															{{ curDay.hhmmTransport + ' | ' + getCurrency(curDay.salaryTransport) }}
														</div>
														<div class="column-2" v-else>
															{{ curDay.hhmmTransport }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(570, 'Night') }}</div>
														<div class="column-2" v-if="canSeeSalary">
															{{ curDay.hhmmNight + ' | ' + getCurrency(curDay.salaryHourNight) }}
														</div>
														<div class="column-2" v-else>
															{{ curDay.hhmmNight }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(580, 'Anticipated') }}</div>
														<div class="column-2" v-if="canSeeSalary">
															{{ curDay.hhmmTooEarly + ' | ' + getCurrency(curDay.salaryHourTooEarly) }}
														</div>
														<div class="column-2" v-else>
															{{ curDay.hhmmTooEarly }}
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(600, 'Lunch start') }}</div>
														<div class="column-2">{{ getHours(curDay.lunchStrTime) }}</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(650, 'Lunch end') }}</div>
														<div class="column-2">{{ getHours(curDay.lunchEndTime) }}</div>
													</li>
													<li
														v-if="
															(showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()) &&
															curDay.totPerDiem > 0
														"
													>
														<div class="column-1">{{ FormMSG(2200, 'Per Diem') }}</div>
														<div class="column-2">
															<div class="d-flex justify-content-end align-items-center">
																<button
																	class="btn-transparent text-color-rhapsody-in-blue clearfixx"
																	v-b-tooltip.hover.left.html="tooltipContentPerIdem(curDay)"
																>
																	<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
																</button>
																<div style="margin-top: 4px">{{ getCurrency(curDay.totPerDiem) }}</div>
															</div>
														</div>
													</li>
													<li v-if="curDay.totAllowances > 0">
														<div class="column-1">{{ FormMSG(2210, 'Allowances') }}</div>
														<div class="column-2">
															<div class="d-flex justify-content-end align-items-center">
																<button
																	class="btn-transparent text-color-rhapsody-in-blue"
																	v-b-tooltip.hover.left.html="tooltipContentAllowance(curDay)"
																>
																	<component :is="getLucideIcon('Info')" :color="ICONS.INFO.color" :size="16" />
																</button>
																<div style="margin-top: 4px">{{ getCurrency(curDay.totAllowances) }}</div>
															</div>
														</div>
													</li>
													<li>
														<div class="column-1">{{ FormMSG(590, 'Rate') }}</div>
														<div class="column-2">{{ curDay.dayRate + '%' }}</div>
													</li>
												</ul>
											</div>
										</b-col>
									</b-row>
								</div>
							</b-collapse>
						</b-col>
					</b-row>
					<div v-if="curDay.id !== 0" class="divider-line" />
					<b-row style="margin-bottom: 24px; margin-top: 24px">
						<b-col>
							<div
								v-b-toggle.accordion-type
								class="accordion-details"
								style="width: 100%; text-align: left; cursor: pointer"
								@click="handleClickAccordion('type')"
							>
								<div style="width: 90%; display: flex">
									<div>
										<h2 class="fw-600">{{ FormMSG(180, 'Type') }}</h2>
									</div>
									<div>
										<span class="status next-day" style="margin-left: 5px; display: inline-block; text-transform: uppercase">
											{{ getDayType(curDay.dayType) }}
										</span>
									</div>
								</div>
								<div style="width: 10%; padding-top: 10px">
									<b-icon v-if="accordionActive !== 'type'" icon="chevron-down" class="float-right" />
									<b-icon v-else icon="chevron-up" class="float-right" />
								</div>
							</div>
							<b-collapse id="accordion-type" accordion="my-accordion" class="mt-2">
								<div>
									<b-form-select v-model="curDay.dayType" :options="optionsForDefaultDayType" size="md" />
								</div>
								<div v-if="!curDay.stageDayUsed" class="mt-4">
									<b-form-checkbox
										class="pj-cb pb-1"
										id="hours_not_specified"
										v-model="curDay.strEndNotSpecified"
										size="lg"
										:disabled="disableSave"
									>
										{{ FormMSG(124, 'Hours not specified') }}
									</b-form-checkbox>
								</div>
								<div v-if="!curDay.strEndNotSpecified">
									<div v-if="showStageDay()" class="mt-4">
										<b-form-checkbox
											class="pj-cb pb-1"
											id="stageDayUsed"
											v-model="curDay.stageDayUsed"
											size="lg"
											:disabled="disableSave || !hasAvailableStageDay"
										>
											{{ FormMSG(24, 'Use Stage Day') }}
										</b-form-checkbox>
									</div>
								</div>
							</b-collapse>
						</b-col>
					</b-row>
					<div class="divider-line" />
					<b-row style="margin-bottom: 24px; margin-top: 24px">
						<b-col>
							<div
								v-b-toggle.accordion-lunch
								class="accordion-details"
								style="width: 100%; text-align: left; cursor: pointer"
								@click="handleClickAccordion('lunch')"
							>
								<div style="width: 90%; display: flex">
									<div>
										<h2 class="fw-600">{{ FormMSG(181, 'Lunch') }}</h2>
									</div>
									<div>
										<span class="status next-day" style="margin-left: 5px; display: inline-block">
											{{ getLunchPerDiemCurDayString() }}
										</span>
									</div>
								</div>
								<div style="width: 10%; padding-top: 10px">
									<b-icon v-if="accordionActive !== 'lunch'" icon="chevron-down" class="float-right" />
									<b-icon v-else icon="chevron-up" class="float-right" />
								</div>
							</div>
							<b-collapse id="accordion-lunch" accordion="my-accordion" class="mt-2">
								<div class="w-100">
									<b-form-checkbox class="pj-cb pb-1" id="lunchNotSpec" v-model="curDay.notSpecifiedLunch" size="lg" :disabled="disableSave">
										{{ FormMSG(280, 'Not specified') }}
									</b-form-checkbox>
									<b-row v-if="!curDay.stageDayUsed && showLunch() && !curDay.notSpecifiedLunch">
										<b-col cols="12">
											<div class="total">
												<b-row>
													<b-col>
														<StartEndTimeOverRange
															:start-backward-day="1"
															:start-forward-day="1"
															:end-backward-day="1"
															:end-forward-day="2"
															:value="{
																start: curDay.lunchStrTime,
																end: curDay.lunchEndTime,
																date: curDay.date
															}"
															:disable-save="disableSave"
															:hide-label-date="days.length > 1"
															:start-label="FormMSG(29, 'Lunch Start:')"
															:end-label="FormMSG(30, 'Lunch End:')"
															@change-range="updateLunchTimes"
															:days="days"
															:multipleSelectionDate="multipleSelectionDate"
														/>
													</b-col>
												</b-row>
											</div>
										</b-col>
									</b-row>
									<b-row class="mt-2">
										<b-col cols="12" class="text-center p-0" v-if="curDay.lunch">
											<h2 class="total-hours">{{ getCurrency(!curDay.lunchPerDiem ? 0 : curDay.lunchPerDiem) }}</h2>
											<p>{{ FormMSG(220, 'Total Amount') }}</p>
										</b-col>
									</b-row>
								</div>
							</b-collapse>
						</b-col>
					</b-row>
					<div class="divider-line" />
					<b-row style="margin-bottom: 24px; margin-top: 24px">
						<b-col>
							<div
								v-b-toggle.accordion-perDiem
								class="accordion-details"
								style="width: 100%; text-align: left; cursor: pointer"
								@click="handleClickAccordion('hotel')"
							>
								<div style="width: 90%; display: flex">
									<div>
										<h2>{{ FormMSG(680, 'Per Diem') }}</h2>
									</div>
									<div v-if="curDay.hotel || curDay.useAbroad || curDay.lunch || curDay.useDinner">
										<span class="status next-day" style="margin-left: 5px; display: inline-block">
											{{ FormMSG(310, 'Yes') }}
										</span>
									</div>
								</div>
								<div style="width: 10%; padding-top: 10px">
									<b-icon v-if="accordionActive !== 'hotel'" icon="chevron-down" class="float-right" />
									<b-icon v-else icon="chevron-up" class="float-right" />
								</div>
							</div>
							<b-collapse id="accordion-perDiem" accordion="my-accordion" class="mt-2">
								<b-row>
									<b-col v-if="showLunchPerDiem()" class="lh-20">
										<b-form-checkbox class="pj-cb pb-1" id="lunch" v-model="curDay.lunch" size="lg" :disabled="disableSave">
											{{ FormMSG(28, 'Lunch Per Diem') }}
										</b-form-checkbox>
									</b-col>
									<b-col v-if="showDinnerPerDiem()">
										<b-form-checkbox class="pj-cb pb-1" id="dinner" v-model="curDay.useDinner" size="lg" :disabled="disableSave">
											{{ FormMSG(281, 'Dinner Per Diem') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<b-row>
									<b-col v-if="showHotelPerDiem()">
										<b-form-checkbox class="pj-cb pb-1" id="perDiem" v-model="curDay.hotel" size="lg" :disabled="disableSave">
											{{ FormMSG(31, 'Hotel Per Diem') }}
										</b-form-checkbox>
									</b-col>
									<b-col v-if="showAbroadPerDiem()">
										<b-form-checkbox class="pj-cb pb-1" id="abroad" v-model="curDay.useAbroad" size="lg" :disabled="disableSave">
											{{ FormMSG(282, 'Abroad Per Diem') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
							</b-collapse>
						</b-col>
					</b-row>
					<div class="divider-line" />
					<b-row style="margin-top: 24px; margin-bottom: 24px" v-if="showTransport()">
						<b-col>
							<div
								v-b-toggle.accordion-transport
								class="accordion-details"
								style="width: 100%; text-align: left; cursor: pointer"
								@click="handleClickAccordion('transport')"
							>
								<div style="width: 90%; display: flex">
									<div>
										<h2>{{ FormMSG(139, 'Transport from/to home') }}</h2>
									</div>
									<div v-if="curDay.hhmmTransport !== '0:00' && curDay.hhmmTransport !== ''">
										<span class="status next-day" style="margin-left: 5px; display: inline-block">
											{{ getHhMmString(curDay.hhmmTransport) }}
										</span>
									</div>
								</div>
								<div style="width: 10%; padding-top: 10px">
									<b-icon v-if="accordionActive !== 'transport'" icon="chevron-down" class="float-right" />
									<b-icon v-else icon="chevron-up" class="float-right" />
								</div>
							</div>
							<!-- <hr /> -->
							<div class="pt-3 pb-2 lh-20">
								<b-form-checkbox
									class="pj-cb pb-1"
									id="specifyTransport"
									v-model="curDay.transportFromHomeToWork"
									size="lg"
									:disabled="disableSave"
								>
									{{ FormMSG(224, 'Specify transport time from home to work') }}
								</b-form-checkbox>
							</div>
							<b-collapse :visible="curDay.transportFromHomeToWork" class="mt-2">
								<div>
									<div>
										<StartEndTimeOverRange
											:start-backward-day="1"
											:start-forward-day="0"
											:end-backward-day="0"
											:end-forward-day="2"
											:hide-label-date="days.length > 1"
											:value="{
												start: curDay.leftHomeAt,
												end: curDay.returnedHomeAt,
												date: curDay.date
											}"
											:disable-save="disableSave"
											:start-label="FormMSG(126, 'Left home at:')"
											:end-label="FormMSG(127, 'Returned home at:')"
											@change-range="updateLeftReturnedTimes"
											:days="days"
											:multipleSelectionDate="multipleSelectionDate"
										/>

										<div class="total mt-3">
											<div class="d-flex flex-row justify-content-center align-items-center">
												<b-col cols="6" class="text-center p-0">
													<h2 class="total-hours">
														{{ curDay.hhmmTransport === '' ? '--:--' : getHhMmString(curDay.hhmmTransport) }}
													</h2>
													<div style="font-size: 14px">{{ FormMSG(201, 'Transport Hours') }}</div>
												</b-col>
												<b-col v-if="canSeeSalary" cols="6" class="text-center p-0">
													<h2 class="total-amount">
														{{ getCurrency(!curDay.salaryTransport ? 0 : curDay.salaryTransport) }}
													</h2>
													<div style="font-size: 14px">{{ FormMSG(205, 'Transport Amount') }}</div>
												</b-col>
											</div>
										</div>
									</div>
								</div>
							</b-collapse>
						</b-col>
					</b-row>
					<div class="divider-line" />
					<b-row style="margin-top: 24px; margin-bottom: 24px">
						<b-col>
							<div
								v-b-toggle.working_site
								class="accordion-details"
								style="width: 100%; text-align: left; cursor: pointer"
								@click="handleClickAccordion('working_site')"
							>
								<div style="width: 90%; display: flex">
									<div>
										<h2>{{ FormMSG(698, 'Working site') }}</h2>
									</div>
								</div>
								<div style="width: 10%; padding-top: 10px">
									<b-icon v-if="accordionActive !== 'working_site'" icon="chevron-down" class="float-right" />
									<b-icon v-else icon="chevron-up" class="float-right" />
								</div>
							</div>
							<b-collapse id="working_site" accordion="my-accordion" class="mt-2">
								<div>
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(587, 'Working site')">
												<v-select
													label="setName"
													v-model="curDay.locationId"
													:options="projectLocationsList"
													:placeholder="FormMSG(594, 'Please select')"
													:reduce="(opt) => opt.id"
												/>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="FormMSG(587, 'Working site')">
												<b-form-input :placeholder="FormMSG(588, 'Enter name of site')" />
											</b-form-group>
										</b-col>
									</b-row>
								</div>
							</b-collapse>
						</b-col>
					</b-row>
					<div class="divider-line" />
					<b-row style="margin-top: 24px; margin-bottom: 24px">
						<b-col>
							<div
								v-b-toggle.allowance
								class="accordion-details"
								style="width: 100%; text-align: left; cursor: pointer"
								@click="handleClickAccordion('allowance')"
							>
								<div style="width: 90%; display: flex">
									<div>
										<h2>{{ FormMSG(289, 'Allowance') }}</h2>
									</div>
								</div>
								<div style="width: 10%; padding-top: 10px">
									<b-icon v-if="accordionActive !== 'allowance'" icon="chevron-down" class="float-right" />
									<b-icon v-else icon="chevron-up" class="float-right" />
								</div>
							</div>
							<b-collapse id="allowance" accordion="my-accordion" class="mt-2">
								<div>
									<b-form-checkbox class="pj-cb pb-1" id="chkMileageToSet" v-model="curDay.useMileageToSet" size="lg">
										{{ FormMSG(265, 'Mileage to set') }}
									</b-form-checkbox>
								</div>
							</b-collapse>
						</b-col>
					</b-row>
					<div class="divider-line" />
					<b-row style="margin-top: 24px; margin-bottom: 24px">
						<b-col>
							<div
								v-b-toggle.commentSection
								class="accordion-details"
								style="width: 100%; text-align: left; cursor: pointer"
								@click="handleClickAccordion('comment')"
							>
								<div style="width: 90%; display: flex">
									<div>
										<h2>{{ FormMSG(26540, 'Comment') }}</h2>
									</div>
								</div>
								<div style="width: 10%; padding-top: 10px">
									<b-icon v-if="accordionActive !== 'comment'" icon="chevron-down" class="float-right" />
									<b-icon v-else icon="chevron-up" class="float-right" />
								</div>
							</div>
							<b-collapse id="commentSection" accordion="my-accordion" class="mt-2">
								<div>
									<b-form-textarea :disabled="disableSave" v-model="curDay.remark" rows="3" />
								</div>
							</b-collapse>
						</b-col>
					</b-row>
					<div class="divider-line" />
				</div>
				<!-- supprimé de la ligne suivante: v-show="!(disableSaveButton || disableSave || dateDisabled() === true)" -->
				<div v-if="(curDay.isAvailable && !allowTimesheetEntryOutOfContract) || allowTimesheetEntryOutOfContract">
					<b-row class="footer-fixed" v-if="!(disableSaveButton || disableSave || dateDisabled() === true)">
						<b-col cols="12">
							<b-button
								variant="primary"
								:disabled="disableSaveButton || disableSave || dateDisabled() === true"
								block
								style="font-size: 16px; font-weight: 700"
								@click="saveCurDay()"
							>
								{{ buttonLabel() }}
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
		<div v-if="useFormFor === 'web'">
			<!-- <pre>{{ curDay }}</pre> -->
			<b-card no-body class="card-border-blue-light">
				<!-- bg-secondary text-white -->
				<b-row
					class="back-with-title cursor-pointer d-flex align-items-center py-1"
					:class="{ 'mt-16': $isPwa() }"
					v-if="!useViewPlanning"
					@click="handleGoBack"
				>
					<b-col cols="8" class="d-flex align-items-center pl-0">
						<div class="icon">
							<component :is="getLucideIcon('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" />
						</div>
						<div class="ml-1" style="margin-top: 4px">
							<h2>{{ FormMSG(703, 'Back') }}</h2>
						</div>
					</b-col>
				</b-row>
				<b-card-body>
					<!-- <b-row> -->
					<fieldset class="scheduler-border border-color-blue-streak">
						<legend class="scheduler-border text-color-blue-streak fs-16">
							{{ FormMSG(156, 'Dates') }}
						</legend>
						<div v-if="!useViewPlanning">
							<b-col v-if="!hideAlert" cols="12">
								<!-- <h2>{{ FormMSG(49, 'Add a new day') }}</h2> -->
								<div class="alert alert-info">
									{{ caption2 }}
								</div>
								<div v-if="disableSave" class="alert alert-warning">
									{{ FormMSG(11, 'A timesheet has already been submitted for this day. Please choose another date.') }}
								</div>
								<div
									v-if="
										multipleSelectionDate === false &&
										selectedDateFromMonth === true &&
										allowTimesheetEntryOutOfContract === false &&
										curDay.isAvailable === false &&
										screenUsedTheForm === isTimeCard
									"
									class="d-flex align-items-center alert alert-warning"
									role="alert"
								>
									<component :is="getLucideIcon('AlertTriangle')" color="#CD9A16" :size="22" />
									<div class="ml-2" style="font-size: 1rem">
										{{ FormMSG(701, `You don't have any contract valid on this selected date.`) }}
									</div>
								</div>
								<div
									v-if="
										screenUsedTheForm === isTimeCard &&
										multipleSelectionDate === false &&
										curDay.isAvailable === false &&
										allowTimesheetEntryOutOfContract === true
									"
									class="d-flex align-items-center alert alert-warning"
									role="alert"
								>
									<component :is="getLucideIcon('CheckCircle')" color="#CD9A16" :size="22" />
									<div class="ml-2" style="font-size: 1rem">
										{{
											FormMSG(
												702,
												`Even you don't have any contract valid on this selected date. The "app" let you enter your work details anyway.`
											)
										}}
									</div>
								</div>
							</b-col>
							<div v-if="useFormInModal && timeCardToEdit">
								<b-row>
									<b-col cols="12">
										<table class="location">
											<thead>
												<tr>
													<th scope="col" class="p-0 pl-2" style="width: 35%">{{ FormMSG(535, 'Name') }}</th>
													<th scope="col" class="p-0 pl-2" style="width: 55%">{{ FormMSG(536, 'Dates') }}</th>
												</tr>
											</thead>
											<tbody>
												<template v-for="list in listUserWithDates">
													<tr v-for="(date, index2) in list.dates" :key="index2" class="dynamic_address_location_container">
														<td :rowspan="list.dates.length" v-if="index2 === 0" class="p-0 pl-2">
															<div class="fs-14 fw-700">{{ list.fullName }}</div>
														</td>
														<td class="p-0 pl-2">
															<div class="fs-14">{{ formatedDate(date) }}</div>
														</td>
													</tr>
												</template>
											</tbody>
										</table>
									</b-col>
								</b-row>
							</div>
							<div v-else>
								<b-row class="d-flex justify-content-start">
									<b-col sm="6" class="pt-0">
										<div v-if="!hideStatus" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<div class="wrap-status justify-content-start pb-1" v-if="!isFilmSingle">
												<div :class="`status ${curDay.statusClass}`" style="font-size: 0.925rem">
													{{ curDay.validatedStatus }}
												</div>
											</div>
										</div>
									</b-col>
									<b-col v-if="multipleSelectionDate" sm="6">
										<div v-if="days.length === 0" class="fs-16 fw-700">{{ FormMSG(589, 'You must choice dates') }}</div>
										<div v-else class="fs-16 fw-700">{{ FormMSG(547, 'Selected dates') }}</div>
									</b-col>
								</b-row>
							</div>
							<b-row class="pl-3" v-if="screenUsedTheForm === isTimeCard || (screenUsedTheForm === isEntryForCrew && timeCardToEdit === null)">
								<b-col sm="6" class="pl-0">
									<div class="ml-3 pb-4">
										<Calendar
											v-if="multipleSelectionDate"
											:attributes="attributes"
											is-expanded
											:locale="getCurLangStr"
											:max-date="new Date()"
											@dayclick="onDayClick"
											@update:from-page="calPageUpdated"
										/>
										<Calendar
											v-if="!multipleSelectionDate"
											v-model="allowedEntryOutOfDateAttributes[0].dates"
											:attributes="allowedEntryOutOfDateAttributes"
											:from-page="dateToShow"
											:locale="getCurLangStr"
											:columns="1"
											:available-dates="allAvailableDates"
											is-expanded
											@dayclick="calDateSelected"
											@update:from-page="calPageUpdated"
										/>
									</div>
								</b-col>
								<b-col v-if="multipleSelectionDate" sm="6" class="pr-2 list-selected-dates-form-time-card">
									<div v-if="days.length > 0">
										<perfect-scrollbar style="height: 255px">
											<div v-for="(day, index) in days" :key="index">
												<div class="fs-14">{{ day.id | formatDate('dddd MMMM DD, YYYY') }}</div>
											</div>
										</perfect-scrollbar>
									</div>
								</b-col>
							</b-row>
						</div>
						<div class="pb-4" v-else>
							<b-row>
								<b-col cols="12">
									<table class="location">
										<thead>
											<tr>
												<th scope="col" class="p-0 pl-2" style="width: 35%">{{ FormMSG(535, 'Name') }}</th>
												<th scope="col" class="p-0 pl-2" style="width: 55%">{{ FormMSG(536, 'Dates') }}</th>
												<th scope="col" class="p-0 pl-2" style="width: 10%"></th>
											</tr>
										</thead>
										<tbody>
											<template v-for="(list, index) in listUserWithDates">
												<tr
													v-for="(date, index2) in list.dates"
													:key="`${index2 + Math.random() * 1000000}`"
													class="dynamic_address_location_container"
												>
													<td :rowspan="list.dates.length" v-if="index2 === 0" class="p-0 pl-2">
														<div class="fs-14 fw-700">{{ list.fullName }}</div>
													</td>
													<td class="p-0 pl-2">
														<div class="fs-14">{{ formatedDate(date) }}</div>
													</td>
													<td class="text-danger text-center p-0">
														<button class="btn-transparent text-danger" @click="handleClickDeleteDate(index, date)">
															<component :is="getLucideIcon('Trash')" :size="16" />
														</button>
													</td>
												</tr>
											</template>
										</tbody>
									</table>
								</b-col>
							</b-row>
						</div>
					</fieldset>
					<div v-if="!useViewPlanning && timeCardToEdit === null">
						<fieldset v-if="!hidePersonSelector || !hideDepartmentSelector" class="scheduler-border border-color-blue-streak">
							<legend class="scheduler-border text-color-blue-streak fs-16">
								{{ FormMSG(157, 'Persons') }}
							</legend>
							<b-row>
								<b-col md="6" v-if="!hidePersonSelector">
									<b-form-group :label="FormMSG(2, 'Add a person')">
										<b-input-group class="mb-3">
											<v-select
												:disabled="disableSave || disableUserSelect"
												class="w-100"
												:value="singleUserSelected"
												:placeholder="FormMSG(6, 'Select a person')"
												:options="userList"
												label="message"
												@input="handleInputSelectUser"
												@option:selected="handleSelectUser"
												:reduce="(option) => option.value"
											></v-select>
										</b-input-group>
									</b-form-group>
								</b-col>
								<div v-if="!hidePersonSelector" class="mt-5">
									<b>{{ this.FormMSG(564, 'OR') }}</b>
								</div>
								<b-col v-if="!hideDepartmentSelector">
									<b-form-group :label="FormMSG(3, 'Add a department')">
										<b-input-group class="mb-3">
											<v-select
												:disabled="disableSave || disableUserSelect"
												class="w-100"
												v-model="department"
												:placeholder="FormMSG(6, 'Select a person')"
												:options="allDepartements"
												label="message"
												@option:selected="handleSelectDepartement"
												:reduce="(option) => option.value"
											></v-select>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="selectedUser.length > 0">
								<b-col cols="2">
									<div class="d-flex">
										<h6>
											<b>{{ FormMSG(612, 'Person list') }}</b>
										</h6>
										<div class="w-10 ml-2" style="margin-top: -1px">
											<b-badge pill class="badge-variant">
												<span class="badge-notification" style="font-size: 0.785rem">{{ selectedUser.length }}</span>
											</b-badge>
										</div>
									</div>
								</b-col>
								<b-col cols="8">
									<div class="divider-dotted"></div>
								</b-col>
								<b-col cols="2" class="d-flex justify-content-end">
									<h6 class="d-flex align-items-center cursor-pointer" @click="clearAllSelectedUser()">
										<component
											:is="getLucideIcon(ICONS.X_CLEAR.name)"
											:color="ICONS.X_CLEAR.color"
											class="mr-1"
											:size="18"
											:stroke-width="3"
										/>
										<div style="margin-top: 2px">
											<b>{{ FormMSG(189, 'Clear All') }}</b>
										</div>
									</h6>
								</b-col>
							</b-row>
							<b-row class="px-3 mt-2" v-if="selectedUser.length > 0" :class="`${selectedUser.length > 0 ? 'mb-2' : ''}`">
								<b-col cols="2" class="d-flex mb-2" v-for="user in selectedUser" :key="user.value">
									<div class="d-inline-flex align-items-center" @click="removeUser(user.value)">
										<component
											:is="getLucideIcon(ICONS.TRASH.name)"
											:color="ICONS.TRASH.color"
											class="mr-2 cursor-pointer"
											@click="removeUser(user.value)"
											:size="18"
										/>
										<b-tooltip :target="`tooltip-del-${user.value}`" triggers="hover" placement="left">{{
											FormMSG(47, 'Delete')
										}}</b-tooltip>
										<div :title="user.message" class="text-truncate" style="max-width: 140px; margin-top: 2px" v-b-tooltip.hover.top>
											<b> {{ user.message }}</b>
											<div class="text-truncate" style="font-size: 0.85rem; color: rgba(6, 38, 62, 0.64); margin-top: -1px">
												{{ user.departmentName }}
											</div>
										</div>
									</div>
								</b-col>
							</b-row>
						</fieldset>
					</div>
					<fieldset class="scheduler-border border-color-blue-streak">
						<legend class="scheduler-border text-color-blue-streak fs-16">
							{{ FormMSG(169, 'Work details') }} <span v-if="!multipleSelectionDate && !useViewPlanning">{{ curDay.date | frDate }}</span>
						</legend>
						<b-col xl="12" class="pt-4">
							<b-form-group :label="FormMSG(23, 'Type:')" label-for="dayType">
								<b-form-select v-model="curDay.dayType" :options="optionsForDefaultDayType" size="md" />
							</b-form-group>
							<b-collapse :visible="!curDay.stageDayUsed">
								<div v-if="!curDay.stageDayUsed" class="mt-4">
									<b-form-checkbox
										class="pj-cb pb-1"
										id="hours_not_specified"
										v-model="curDay.strEndNotSpecified"
										size="lg"
										:disabled="disableSave"
									>
										{{ FormMSG(124, 'Hours not specified') }}
									</b-form-checkbox>
								</div>
							</b-collapse>
							<b-collapse :visible="!curDay.strEndNotSpecified">
								<div v-if="!curDay.strEndNotSpecified">
									<div v-if="showStageDay()" class="mt-4">
										<b-form-checkbox
											class="pj-cb pb-1"
											id="stageDayUsed"
											v-model="curDay.stageDayUsed"
											size="lg"
											:disabled="disableSave || !hasAvailableStageDay"
										>
											{{ FormMSG(24, 'Use Stage Day') }}
										</b-form-checkbox>
									</div>
								</div>
								<div v-if="!curDay.strEndNotSpecified">
									<div v-if="showStageDay() && !curDay.stageDayUsed" class="my-2">
										<b>{{ FormMSG(25, 'Or') }}</b>
									</div>
								</div>
								<div v-if="!curDay.strEndNotSpecified">
									<b-row v-if="!curDay.stageDayUsed" class="pb-2">
										<b-col :md="useFormInModal ? 12 : 8">
											<StartEndTimeOverRange
												:start-backward-day="1"
												:start-forward-day="0"
												:end-backward-day="0"
												:end-forward-day="1"
												:value="{
													start: curDay.strTime,
													end: curDay.endTime,
													date: curDay.date
												}"
												:disable-save="disableSave"
												:hide-label-date="days.length > 1"
												@change-range="updateStrEndTimes"
												:multipleSelectionDate="multipleSelectionDate"
												:days="days"
												:dataOneSelect="listUserWithDates"
											/>
										</b-col>
									</b-row>
								</div>
							</b-collapse>
							<!--     PERDIEM         -->
							<!-- <hr v-if="showLunch() && !curDay.stageDayUsed" /> -->
							<div class="pt-3 divider-line" v-if="showLunch() && !curDay.stageDayUsed" />
							<div v-if="showLunch()" class="pt-3 lh-20">
								<b-form-checkbox class="pj-cb pb-1" id="lunchNotSpec" v-model="curDay.notSpecifiedLunch" size="lg" :disabled="disableSave">
									{{ FormMSG(280, 'Not specified') }}
								</b-form-checkbox>
							</div>
							<div v-if="!curDay.stageDayUsed && showLunch() && !curDay.notSpecifiedLunch" class="my-2">
								<b>{{ FormMSG(25, 'Or') }}</b>
							</div>
							<b-row v-if="!curDay.stageDayUsed && showLunch() && !curDay.notSpecifiedLunch" class="pb-2">
								<b-col :md="useFormInModal ? 12 : 8">
									<StartEndTimeOverRange
										:start-backward-day="1"
										:start-forward-day="1"
										:end-backward-day="1"
										:end-forward-day="2"
										:value="{
											start: curDay.lunchStrTime,
											end: curDay.lunchEndTime,
											date: curDay.date
										}"
										:disable-save="disableSave"
										:start-label="FormMSG(29, 'Lunch Start:')"
										:end-label="FormMSG(30, 'Lunch End:')"
										:hide-label-date="days.length > 1"
										@change-range="updateLunchTimes"
										:multipleSelectionDate="multipleSelectionDate"
										:days="days"
										:dataOneSelect="listUserWithDates"
									/>
								</b-col>
							</b-row>
							<div class="pt-3 divider-line" />
							<b-row class="pt-3 lh-20">
								<b-col v-if="showLunchPerDiem()">
									<b-form-checkbox class="pj-cb pb-1" id="lunch" v-model="curDay.lunch" size="lg" :disabled="disableSave">
										{{ FormMSG(28, 'Lunch Per Diem') }}
									</b-form-checkbox>
								</b-col>
								<b-col v-if="showDinnerPerDiem()">
									<b-form-checkbox class="pj-cb pb-1" id="dinner" v-model="curDay.useDinner" size="lg" :disabled="disableSave">
										{{ FormMSG(281, 'Dinner Per Diem') }}
									</b-form-checkbox>
								</b-col>
								<b-col v-if="showHotelPerDiem()">
									<b-form-checkbox class="pj-cb pb-1" id="hotel" v-model="curDay.hotel" size="lg" :disabled="disableSave">
										{{ FormMSG(31, 'Hotel Per Diem') }}
									</b-form-checkbox>
								</b-col>
								<b-col v-if="showAbroadPerDiem()">
									<b-form-checkbox class="pj-cb pb-1" id="abroad" v-model="curDay.useAbroad" size="lg" :disabled="disableSave">
										{{ FormMSG(282, 'Abroad Per Diem') }}
									</b-form-checkbox>
								</b-col>
							</b-row>
							<!--     TRANSPORT         -->
							<div class="pt-3 divider-line" />
							<!-- <pre>{{ curDay }}</pre> -->
							<div class="pt-3" v-if="showTransport()">
								<!-- <hr /> -->
								<div class="lh-20">
									<b-form-checkbox
										class="pj-cb pb-1"
										id="specifyTransport"
										v-model="curDay.transportFromHomeToWork"
										size="lg"
										:disabled="disableSave"
									>
										{{ FormMSG(224, 'Specify transport time from home to work') }}
									</b-form-checkbox>
								</div>
								<b-collapse :visible="curDay.transportFromHomeToWork">
									<h3 class="mt-3 mb-3">{{ FormMSG(139, 'Transport from/to home') }}</h3>
									<b-row>
										<b-col :md="useFormInModal ? 12 : 8">
											<StartEndTimeOverRange
												:start-backward-day="1"
												:start-forward-day="0"
												:end-backward-day="0"
												:end-forward-day="2"
												:value="{
													start: curDay.leftHomeAt,
													end: curDay.returnedHomeAt,
													date: curDay.date
												}"
												:disable-save="disableSave"
												:hide-label-date="days.length > 1"
												:start-label="FormMSG(126, 'Left home at:')"
												:end-label="FormMSG(127, 'Returned home at:')"
												@change-range="updateLeftReturnedTimes"
												:days="days"
												:multipleSelectionDate="multipleSelectionDate"
											/>
										</b-col>
									</b-row>
								</b-collapse>
							</div>
						</b-col>
						<b-col>
							<div class="pt-3 divider-line" />
							<div class="lh-20">
								<b-row class="pt-3">
									<b-col cols="3">
										<b-form-group :label="FormMSG(587, 'Working site')">
											<v-select
												v-if="!showOtherLocation"
												v-model="curDay.locationId"
												label="setName"
												:options="projectLocationsList"
												:placeholder="FormMSG(594, 'Please select')"
												:reduce="(opt) => opt.id"
											/>
											<b-form-input v-else :value="FormMSG(541, 'Other')" disabled />
										</b-form-group>
									</b-col>
									<b-col v-if="showOtherLocation" cols="3" class="pt-28">
										<b-form-input v-model="otherLocation" :placeholder="FormMSG(521, 'Please specify')" />
									</b-col>
								</b-row>
							</div>
						</b-col>
						<b-col>
							<div class="pt-2 divider-line" />
							<div class="pt-3 lh-20">
								<b-form-checkbox class="pj-cb pb-1" id="chkMileageToSet" v-model="curDay.useMileageToSet" size="lg">
									{{ FormMSG(265, 'Mileage to set') }}
								</b-form-checkbox>
							</div>
						</b-col>
						<b-row class="w-100" v-if="curDay.comment.length">
							<div class="pt-3 divider-line" />
							<b-col cols="12" class="px-3 ml-3">
								<b-form-group :label="FormMSG(32, 'Comment:')" label-for="comment">
									<b-form-textarea id="comment" v-model="curDay.comment" rows="3" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="w-100">
							<div class="pt-3 divider-line" />
							<b-col cols="12" class="px-3 ml-3">
								<b-form-group :label="FormMSG(32, 'Comment:')" label-for="commentss">
									<b-form-textarea :disabled="disableSave" id="commentss" v-model="curDay.remark" rows="3" />
								</b-form-group>
							</b-col>
						</b-row>
						<div class="pt-2 divider-line" />
					</fieldset>
					<div v-if="!hideSaveBtn" class="d-flex justify-content-end">
						<b-row v-if="(curDay.isAvailable && !allowTimesheetEntryOutOfContract) || allowTimesheetEntryOutOfContract">
							<b-col sm="12" class="save-mobile-floating-button clearfix">
								<b-button
									variant="outline-primary"
									v-if="!(disableSaveButton || disableSave || dateDisabled() === true)"
									size="md"
									@click="saveCurDay()"
									class="d-flex justify-content-center align-items-center w-100 px-4"
								>
									<component :is="getLucideIcon('CalendarPlus')" :size="20" class="mr-2" v-if="newDaySelected" />
									<component :is="getLucideIcon('CalendarCheck2')" :size="20" class="mr-2" v-else />
									<div style="margin-top: 1px">{{ buttonLabel() }}</div>
								</b-button>
							</b-col>
						</b-row>
					</div>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="successModal"
						:title="FormMSG(34, 'Success!')"
						class="modal-success"
						ok-variant="success"
						ok-only
						@ok="successModal = false"
						modal-class="mui-animation"
						:fade="false"
					>
						{{ FormMSG(35, "Your information has been saved. Don't forget to send your timesheets for validation with the form below.") }}
					</b-modal>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="successModalDelete"
						:title="FormMSG(34, 'Success!')"
						class="modal-success"
						ok-variant="success"
						ok-only
						@ok="successModalDelete = false"
						modal-class="mui-animation"
						:fade="false"
					>
						{{ FormMSG(36, 'Your information has been deleted.') }}
					</b-modal>
					<!--show modal when user no contract-->
					<b-modal v-model="showPopupInfo" hide-footer no-close-on-backdrop no-close-on-esc modal-class="mui-animation" :fade="false">
						<template #modal-title>
							<span class="w-94">
								<component :is="getLucideIcon(ICONS.ALERT_TRIANGLE.name)" :color="ICONS.ALERT_TRIANGLE.color" :size="18" />
								&nbsp;{{ FormMSG(37, 'No valid contract on this day') }}
							</span>
						</template>
						<div style="display: flex; flex-direction: column; gap: 5px">
							<div
								v-for="item of dataToPopupInfo"
								:key="generateSecureId(`${item.id + Math.random() * 1000000}`)"
								style="border: 2px solid #cad0d7; padding: 10px; border-radius: 5px; gap: 10px"
							>
								<div>
									<div>{{ FormMSG(38, 'User name:') }} {{ item.user.name }} {{ item.user.firstName }}</div>
									<div>{{ FormMSG(39, 'Date:') }} {{ reverseDate(item.date) }}</div>
								</div>
							</div>
						</div>
						<template>
							<div class="w-100 float-right">
								<b-button variant="outline-primary" size="sm" class="mt-3 float-right" @click="closePopup">
									{{ FormMSG(40, 'Close') }}
								</b-button>
							</div>
						</template>
						<!-- <b-button class="mt-3" block @click="showPopupInfo = !showPopupInfo">Close</b-button> -->
					</b-modal>
					<!--     TABLE OF TSDAYS       sort-by="date"    use striped to highlight each even row   @row-selected="rowSelected" -->
				</b-card-body>
			</b-card>
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="showWarningOutOfContractDate"
			:title="FormMSG(699, 'Out of contract date')"
			ok-only
			ok-variant="outline-primary"
			:ok-title="FormMSG(700, 'Ok')"
			hide-header-close
			no-close-on-esc
			no-close-on-backdrop
			modal-class="mui-animation"
			:fade="false"
		>
			<div class="alert alert-warning">{{ FormMSG(701, `You don't have any contract valid on this selected date.`) }}</div>
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getLangISOByIndex } from '@/helpers/translate';
import { store } from '@/store';
import { isNil } from '@/shared/utils';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { saveTsDay, saveTsDaysForCrew, getStageDay } from '@/cruds/timesheet.crud';
import StartEndTimeOverRange from '@/components/StartEndTimeOverRange';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import CalendarWork from '@/components/Work/CalendarWork';
import moment from 'moment';
import _ from 'lodash';
import { getUsers, GetEncodingValidation } from '@/cruds/users.crud';
import { getDepartments } from '@/cruds/department.crud';
import { myContracts, contracts } from '@/cruds/contract.crud';
import { mapActions, mapGetters } from 'vuex';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { generateSecureId } from '@/shared/utils';
import { ACTION_EFC_TIMESHEET } from '@/utils/utils';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

export default {
	name: 'FormTimeCard',
	mixins: [languageMessages, GlobalMixin, isSingleProjectMixin],
	components: {
		StartEndTimeOverRange,
		Calendar,
		CalendarWork,
		PerfectScrollbar
	},
	props: {
		tag: { type: String, default: 'b-card' },
		useFormFor: { type: String, default: 'web', validator: (v) => ['mobile', 'web'].includes(v) },
		useFormInModal: { type: Boolean, default: false, required: false },
		timeCards: { type: Array, default: () => [], required: false },
		timeCardToEdit: { type: Object, default: null, required: false },
		disableUserSelect: { type: Boolean, default: false, required: false },
		hideAlert: { type: Boolean, default: false, required: false },
		hideStatus: { type: Boolean, default: false, required: false },
		multipleSelectionDate: { type: Boolean, default: false, required: false },
		hideSaveBtn: { type: Boolean, default: false, required: false },
		hidePersonSelector: { type: Boolean, default: true, required: false },
		hideDepartmentSelector: { type: Boolean, default: true, required: false },
		screenUsedTheForm: { type: String, default: 'time-card', validator: (v) => ['time-card', 'entry-for-crew'].includes(v), required: false },
		userId: { type: Number, default: +store.state.myProfile.id, required: false },
		submitForm: { type: Boolean, default: false, required: false },
		externalDates: { type: Array, default: () => [], required: false },
		useViewPlanning: { type: Boolean, default: false, required: false },
		handleFormTimeCardSubmitSuccess: { type: Function, required: false },
		isActionCrud: { type: String, required: false }
	},
	data() {
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		return {
			erreur: '',
			generateSecureId,
			addData,
			updateData,
			deleteData,
			tsDate: null,
			dateToShow: null,
			curDay: {
				id: 0,
				date: moment().format('YYYY-MM-DD') + 'T00:00:00Z',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: false,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				remark: '',
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false,
				strEndNotSpecified: false,
				locationId: 0
			},
			newCurDay: {
				id: 0,
				date: '2019-09-01',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: true,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false,
				strEndNotSpecified: false,
				locationId: 0,
				remark: ''
			},
			activeView: 'month',
			curMonthStr: `${moment().format('YYYY')}-${moment().format('MM')}`,
			TsDays: [],
			newDaySelected: false,
			originalCurDay: {},
			myContracts: [],
			contract: {
				defaultDayType: 0,
				hotelPerDiem: 0,
				lunchPerDiem: 0,
				lunchMinimum: 60,
				totMinPerDay: 480 // DEFAULT 8 HOUR WORK
			},
			valueCurDayFromDb: false,
			accordionActive: null,
			disableSaveButton: false,
			successModal: false,
			successModalDelete: false,
			userList: [],
			selectedUser: [],
			singleUserSelected: null,
			department: '',
			allDepartements: [],
			validationDepartments: [],
			showPopupInfo: false,
			dataToPopupInfo: [],
			days: [],
			locations: [],
			allUsers: [],
			listUserWithDates: [],
			otherLocation: '',
			allAvailableDates: [],
			isAvailable: false,
			availableComputed: false,
			showWarningOutOfContractDate: false,
			minContractDate: null,
			selectedDateFromMonth: false,
			hasAvailableStageDay: false,
			displayAlert: false,
			startHourOffice: 8,
			startLunchOffice: 12,
			endHourOffice: 17,
			endLunchOffice: 13,
			isTimeCard: 'time-card',
			isEntryForCrew: 'entry-for-crew',
			lazyDisplayingElForEntry: false
		};
	},
	computed: {
		...mapGetters({
			projectLocationsList: 'locations/projectLocationsList',
			currLocation: 'locations/currLocation'
		}),
		canSeeSalary() {
			return !store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget();
		},
		showCollapseEnterTimeMobile() {
			if (this.curDay.strEndNotSpecified === false) {
				return true;
			}
			if (this.curDay.stageDayUsed === false) {
				return true;
			}

			return false;
		},
		showOtherLocation() {
			return this.locations.length === 0;
		},
		dates() {
			return this.days.map((day) => day.date);
		},
		attributes() {
			return this.dates.map((date) => ({
				highlight: 'red',
				dates: date
			}));
		},
		getCurLangStr() {
			return getLangISOByIndex(store.appLanguage()).two;
		},
		allowedEntryOutOfDateAttributes() {
			// display items in the calendar
			const curDayDate = moment(this.curDay.date).format('YYYY-MM-DD');

			let configs = [
				{
					key: 'notSubmitted',
					dot: {
						color: 'red'
					},
					dates: []
				},
				{
					key: 'submitted',
					dot: {
						color: 'blue'
					},
					dates: []
				},
				{
					key: 'validatedHOD',
					dot: {
						color: 'yellow'
					},
					dates: []
				},
				{
					key: 'refused',
					dot: {
						color: 'red'
					},
					dates: []
				},
				{
					key: 'validated',
					dot: {
						color: 'green'
					},
					dates: []
				},
				{
					key: 'currentDay',
					dates: new Date(curDayDate),
					popover: true
				}
			];

			configs[5].highlight = !this.allowTimesheetEntryOutOfContract ? 'red' : { color: 'blue', fillMode: 'outline' };

			for (let i in this.TsDays) {
				const da = new Date(moment(this.TsDays[i].date).format('YYYY-MM-DD'));
				if (this.TsDays[i].validated === 0) {
					configs[0].dates.push(da);
				} else if (this.TsDays[i].validated === 1) {
					configs[1].dates.push(da);
				} else if (this.TsDays[i].validated === 2) {
					configs[2].dates.push(da);
				} else if (this.TsDays[i].validated === 4 || this.TsDays[i].validated === 16) {
					configs[3].dates.push(da);
				} else if (this.TsDays[i].validated === 8) {
					configs[4].dates.push(da);
				}
			}

			return configs;
		},
		caption2() {
			return this.FormMSG(13, 'Enter the work done for every day. Then, send your data for validation to your head of department');
		},
		disableSave() {
			if (this.isFilmSingle || this.curDay.validated === 0 || this.curDay.validated === 4 || this.curDay.validated === 16) {
				return false;
			} else {
				return true;
			}
		},
		showTabToday() {
			let curDate = new Date(this.curDay.date);
			curDate = moment(curDate).format('YYYY-MM-DD');
			let date = new Date();
			date = moment(date).format('YYYY-MM-DD');
			if (this.activeView === 'day' && curDate !== date) {
				return true;
			}

			return false;
		},
		optionsForDefaultDayType() {
			return this.FormMenu(1010);
		},
		allowTimesheetEntryOutOfContract() {
			return store.state.myProject.allowTSEntryOutsideContract;
		}
	},
	watch: {
		externalDates: {
			// date select all from entryforcrew
			async handler(newVal) {
				if (newVal.length > 0) {
					this.listUserWithDates = [];
					let result = [];
					this.initializeCurDay(this.curDay.date);
					if (this.allUsers.length === 0) {
						await this.getUsers();
					}
					this.days = [];
					this.selectedUser = [];
					newVal.map((option) => {
						const findIndexUser = this.allUsers.findIndex((uL) => parseInt(uL.value) === parseInt(option.userId));
						const findIndexInResult = result.findIndex((r) => r.userId === option.userId);
						if (findIndexInResult === -1) {
							result.push({
								userId: option.userId,
								fullName: this.allUsers[findIndexUser].message,
								dates: [moment(option.date, 'DD/MM/YYYY').format('YYYY-MM-DD')]
							});
						} else {
							result[findIndexInResult].dates.push(moment(option.date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
						}
						this.days.push({
							id: moment(option.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
							date: new Date(option.date)
						});
					});
					this.listUserWithDates = result;
					let allData = this.listUserWithDates.map((item) => item.dates).flat();
					let uniqueDateOnly = [...new Set(allData.map(JSON.stringify))];
					if (uniqueDateOnly.length === 1) {
						if (this.isActionCrud === this.addData) {
							let _date = moment(uniqueDateOnly[0]).format('YYYY-MM-DD');
							let strTime = _date + 'T08:00:00+03:00';
							let endTime = _date + 'T17:00:00+03:00';
							let lunchStrTime = _date + 'T12:00:00+03:00';
							let lunchEndTime = _date + 'T13:00:00+03:00';
							let leftHomeAt = _date + 'T08:00:00+03:00';
							let returnedHomeAt = _date + 'T17:00:00+03:00';
							this.curDay.date = _date;
							this.curDay.strTime = strTime;
							this.curDay.endTime = endTime;
							this.curDay.lunchStrTime = lunchStrTime;
							this.curDay.lunchEndTime = lunchEndTime;
							this.curDay.leftHomeAt = leftHomeAt;
							this.curDay.returnedHomeAt = returnedHomeAt;
						}
						if (this.isActionCrud === this.updateData) {
							let _date = moment(uniqueDateOnly[0]).format('YYYY-MM-DD');
							let strTime = this.curDay.strTime;
							let endTime = this.curDay.endTime;
							let lunchStrTime = this.curDay.lunchStrTime;
							let lunchEndTime = this.curDay.lunchEndTime;
							let leftHomeAt = this.curDay.leftHomeAt;
							let returnedHomeAt = this.curDay.returnedHomeAt;
							this.curDay.date = _date;
							this.curDay.strTime = strTime;
							this.curDay.endTime = endTime;
							this.curDay.lunchStrTime = lunchStrTime;
							this.curDay.lunchEndTime = lunchEndTime;
							this.curDay.leftHomeAt = leftHomeAt;
							this.curDay.returnedHomeAt = returnedHomeAt;
						}
					} else {
						this.curDay.date = moment(new Date()).format('YYYY-MM-DD');
					}
				}
			},
			immediate: true,
			deep: true
		},
		timeCards: {
			handler(newVal) {
				this.TsDays = [];
				if (newVal.length > 0) {
					this.TsDays = newVal;
					this.selectCurDay();
				}
			},
			immediate: true
		},
		timeCardToEdit: {
			handler(newVal) {
				if (!isNil(newVal)) {
					this.curDay = { ...this.curDay, ...newVal };
					if (this.curDay.user.name !== undefined && this.curDay.user.firstName !== undefined) {
						let name = this.curDay.user.name[0].toUpperCase() + this.curDay.user.name.substr(1);
						let firstName = this.curDay.user.firstName[0].toUpperCase() + this.curDay.user.firstName.substr(1);
						let fullName = name + ' ' + firstName;
						this.listUserWithDates = [{ userId: this.curDay.user.id, fullName: fullName, dates: [this.curDay.date.split`T`[0]] }];
					}
					if (this.curDay.stageDayUsed == 1) {
						this.curDay.stageDayUsed = true;
					}
					this.originalCurDay = _.cloneDeep(this.curDay);
					this.valueCurDayFromDb = true;
				}
			},
			immediate: true,
			deep: true
		},
		submitForm: {
			async handler(newVal) {
				if (newVal === true) {
					await this.saveCurDay();
					this.$emit('form-time-card:submit-success');
				}
			},
			immediate: true
		},
		projectLocationsList: {
			handler(newVal) {
				this.locations = [...newVal];
			},
			immediate: true
		},
		singleUserSelected: {
			handler(newVal) {
				this.$emit('senddataAuthToSubmitFormSelectMultiple', {
					dates: this.days,
					persons: newVal,
					department: this.department,
					selectTypeMulti: this.multipleSelectionDate,
					useViewPlanning: this.useViewPlanning
				});
				return newVal;
			},
			immediate: true,
			deep: true
		},
		department: {
			handler(newVal) {
				this.$emit('senddataAuthToSubmitFormSelectMultiple', {
					dates: this.days,
					persons: this.singleUserSelected,
					department: newVal,
					selectTypeMulti: this.multipleSelectionDate,
					useViewPlanning: this.useViewPlanning
				});
				return newVal;
			},
			immediate: true,
			deep: true
		},
		listUserWithDates: {
			handler(newVal) {
				this.$emit('senddataAuthToSubmitFormSelectMultiple', {
					dates: newVal,
					persons: this.singleUserSelected,
					department: this.department,
					selectTypeMulti: this.multipleSelectionDate,
					useViewPlanning: this.useViewPlanning
				});
				return newVal;
			},
			immediate: true,
			deep: true
		},
		days: {
			async handler(newVal) {
				if (this.multipleSelectionDate) {
					let filterLocationToSend = {};
					this.$emit('senddataAuthToSubmitFormSelectMultiple', {
						dates: newVal,
						persons: this.singleUserSelected,
						department: this.department,
						selectTypeMulti: this.multipleSelectionDate,
						useViewPlanning: this.useViewPlanning
					});
					await this.getLocations(filterLocationToSend);
				}
				return newVal;
			},
			immediate: true
		},
		'curDay.date': {
			async handler(newVal) {
				if (!_.isNil(newVal)) {
					if (!this.multipleSelectionDate) {
						await this.getLocations({
							StrDate: newVal.split('T')[0] + 'T00:00:00Z'
						});
					}
					if (this.screenUsedTheForm === this.isTimeCard) {
						this.lazyDisplayingElForEntry = true;
						this.allAvailableDates = await this.availableDates();
						this.isAvailableDate(this.allAvailableDates, newVal);
						if (this.allowTimesheetEntryOutOfContract) {
							// const disabledDates = document.querySelectorAll('.vc-is-dark .vc-day-content.is-disabled');
							const disabledDates = document.querySelectorAll('.vc-day-content.is-disabled');
							disabledDates.forEach((disabledDate) => {
								disabledDate.style.color = 'rgba(205, 154, 22, .75)';
							});
						}
					}
				}
			},
			deep: true,
			immediate: false
		},
		'curDay.locationId': {
			async handler(newVal) {
				this.curDay.locationId = parseInt(newVal) === 0 ? null : newVal;
			},
			immediate: true,
			deep: true
		},
		useFormInModal: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		'$route.query': {
			handler(newDate) {
				if (newDate) {
					this.tsDate = newDate.date;
					this.dateToShow = {
						month: parseInt(moment(newDate.date).format('MM')),
						year: parseInt(moment(newDate.date).format('YYYY'))
					};
				}
			},
			deep: true,
			immediate: true
		}
	},
	async created() {
		await this.getValidationDepartments();
		await this.getLocations();
		await this.getDepartment();
		if (this.userList.length === 0) {
			await this.getUsers();
		}

		this.singleUserSelected = _.isNil(this.userId) ? null : '' + this.userId;
		if (this.screenUsedTheForm === this.isEntryForCrew) {
			if (!_.isNil(this.userId)) {
				this.myContracts = await contracts(parseInt(this.userId));
			}
		} else {
			this.myContracts = store.state.myContracts;
			console.log(store.state.myContracts);
		}
		if (this.tsDate) {
			await this.updateDate(this.tsDate);
			await this.calDateSelected(this.tsDate, true);
		}
	},
	methods: {
		clearAllSelectedUser() {
			this.selectedUser = [];
			this.singleUserSelected = null;
			this.department = '';
		},
		...mapActions({
			getProjectLocationList: 'locations/getProjectLocationList',
			setNewLocation: 'locations/setNewLocation',
			updateLocation: 'locations/updateLocation'
		}),
		handleGoBack() {
			const path = `/mytimesheets`;
			return this.$router.push({
				path: path,
				query: {
					activeDraft: true
				}
			});
		},
		async saveLocation() {
			if (this.showOtherLocation && this.otherLocation !== '') {
				await this.setNewLocation();
				this.curDay.locationId = parseInt(this.currLocation.id);
				await this.updateLocation({
					id: this.curDay.locationId,
					location: {
						setName: this.otherLocation
					}
				});
			}
		},
		handleClickDeleteDate(indexFromList, dateSelected) {
			const listIndexed = this.listUserWithDates[indexFromList];
			const findIndexDate = listIndexed.dates.findIndex((d) => d === dateSelected);
			listIndexed.dates.splice(findIndexDate, 1);

			if (listIndexed.dates.length === 0) {
				this.listUserWithDates.splice(indexFromList, 1);
			}

			if (this.listUserWithDates.length === 0) {
				this.$emit('form-time-card:empty-list');
			}
		},
		async getLocations(filter) {
			await this.getProjectLocationList(filter);
		},
		onDayClick(day) {
			const idx = this.days.findIndex((d) => d.id === day.id);
			if (idx >= 0) {
				this.days.splice(idx, 1);
			} else {
				this.days.push({
					id: day.id,
					date: day.date
				});
			}
			if (this.days.length === 1) {
				this.curDay.date = this.days[0].id;
				this.curDay.strTime = this.days[0].id + 'T' + this.curDay.strTime.split('T')[1];
				this.curDay.endTime = this.days[0].id + 'T' + this.curDay.endTime.split('T')[1];
				this.curDay.lunchStrTime = this.days[0].id + 'T' + this.curDay.lunchStrTime.split('T')[1];
				this.curDay.lunchEndTime = this.days[0].id + 'T' + this.curDay.lunchEndTime.split('T')[1];
				this.curDay.leftHomeAt = this.days[0].id + 'T' + this.curDay.leftHomeAt.split('T')[1];
				this.curDay.returnedHomeAt = this.days[0].id + 'T' + this.curDay.returnedHomeAt.split('T')[1];
			} else if (this.days.length === 0) {
				const date = moment(new Date()).format('YYYY-MM-DD');
				this.curDay.date = date;
				this.curDay.strTime = date + 'T' + this.curDay.strTime.split('T')[1];
				this.curDay.endTime = date + 'T' + this.curDay.endTime.split('T')[1];
				this.curDay.lunchStrTime = date + 'T' + this.curDay.lunchStrTime.split('T')[1];
				this.curDay.lunchEndTime = date + 'T' + this.curDay.lunchEndTime.split('T')[1];
				this.curDay.leftHomeAt = date + 'T' + this.curDay.leftHomeAt.split('T')[1];
				this.curDay.returnedHomeAt = date + 'T' + this.curDay.returnedHomeAt.split('T')[1];
			}
		},
		removeUser(val) {
			this.selectedUser = this.selectedUser.filter((usr) => {
				return usr.value !== val;
			});
		},
		async handleSelectDepartement(item) {
			await getUsers({ myProject: true }, null, parseInt(item.value, 10)).then((users) => {
				users.forEach((user) => {
					user.value = user.id;
					user.message = user.fullName;
				});
				let newUser = [
					...users.sort((a, b) => {
						if (a.fullName.toUpperCase() < b.fullName.toUpperCase()) return -1;
						if (a.fullName.toUpperCase() > b.fullName.toUpperCase()) return 1;
						return 0;
					})
				].map((item) => ({
					value: item.id,
					message: item.fullName,
					userId: item.id,
					departementId: item.department,
					departmentName: item.departmentName
				}));
				this.selectedUser = this.selectedUser.concat(newUser.filter((item2) => !this.selectedUser.some((item1) => item1.value === item2.value)));
			});
		},
		async getDepartment() {
			let result = [...(await getDepartments(false, false))].filter((dep) => dep.value !== 0);
			// if (this.validationDepartments.length === 1 && this.validationDepartments[0].value === -1) {
			// 	this.allDepartements = result;
			// 	return;
			// }

			// if (this.validationDepartments.length > 0) {
			// 	let newAllDepartments = [];
			// 	for (let i = 0; i < this.validationDepartments.length; i++) {
			// 		const element = this.validationDepartments[i];

			// 		result.map((res) => {
			// 			if (parseInt(res.value, 10) === parseInt(element.value, 10)) {
			// 				newAllDepartments.push(res);
			// 			}
			// 		});
			// 	}

			// 	this.allDepartements = newAllDepartments;

			// 	return;
			// }
			this.allDepartements = result;
		},
		async getValidationDepartments() {
			await GetEncodingValidation(store.state.myProject.id, store.userID()).then((result) => {
				this.validationDepartments = result.department;
			});
		},
		handleInputSelectUser(payload) {
			this.singleUserSelected = payload;
		},
		handleSelectUser(e) {
			if (!this.selectedUser.some((usr) => usr.value === e.value)) {
				this.selectedUser.push(e);
			}
		},
		async getUsers() {
			await getUsers({ myProject: true }).then((users) => {
				users.forEach((user) => {
					user.value = user.id;
					user.message = user.fullName;
				});
				this.allUsers = users;
				if (this.validationDepartments.length === 1 && this.validationDepartments[0].value === -1) {
					this.userList = [
						{ value: 'null', message: this.FormMSG(521, 'Select a person'), userId: null, departmentId: null, departmentName: null },
						...users.sort((a, b) => {
							if (a.fullName.toUpperCase() < b.fullName.toUpperCase()) return -1;
							if (a.fullName.toUpperCase() > b.fullName.toUpperCase()) return 1;
							return 0;
						})
					].map((item) => ({
						value: item.id,
						message: item.fullName,
						userId: item.id,
						departementId: item.department,
						departmentName: item.departmentName
					}));
					return;
				}
				if (this.validationDepartments.length > 0) {
					let authorizedUser = [];
					for (let i = 0; i < this.validationDepartments.length; i++) {
						const element = this.validationDepartments[i];

						users.map((res) => {
							if (parseInt(res.department, 10) === parseInt(element.value, 10)) {
								authorizedUser.push(res);
							}
						});
					}

					this.userList = [
						{ value: 'null', message: this.FormMSG(521, 'Select a person'), userId: null, departmentId: null, departmentName: null },
						...authorizedUser.sort((a, b) => {
							if (a.fullName.toUpperCase() < b.fullName.toUpperCase()) return -1;
							if (a.fullName.toUpperCase() > b.fullName.toUpperCase()) return 1;
							return 0;
						})
					].map((item) => ({
						value: item.id,
						message: item.fullName,
						userId: item.id,
						departementId: item.department,
						departmentName: item.departmentName
					}));
					return;
				}
			});
		},

		async dateDisabled() {
			if (isNil(this.myContracts)) {
				return true;
			}
			//console.log("in dateDisabled:",this.curDay.date)
			const curDay = new Date(this.curDay.date);
			let lastIndex = this.myContracts.length - 1;
			if (lastIndex === -1) {
				lastIndex = !isNil(this.myContracts) ? this.myContracts.length - 1 : -1;
				if (lastIndex === -1) {
					return true;
				}
			}
			// console.log("in dateDisabled1:",this.curDay.date)
			const today = new Date();
			// disable between contracts if necessary (contract[0] is last contract...)
			for (let i = 0; i <= lastIndex; i++) {
				const start = new Date(this.myContracts[i].startDate);
				if (curDay.getTime() >= start.getTime()) {
					//  console.log("in dateDisabled2:",this.curDay.date)
					let end = new Date(this.myContracts[i].endDate);
					if (end.getTime() > today.getTime()) {
						end = today;
					}
					if (curDay.getTime() <= end.getTime()) {
						// we can modify this date, check if modification in screen
						return !this.curDayIsModified();
						// return false
					}
				}
			}

			return true;
		},
		curDayIsModified() {
			if (JSON.stringify(this.curDay) === JSON.stringify(this.originalCurDay)) {
				return false;
			}

			return true;
		},
		buttonLabel() {
			if (this.newDaySelected) {
				this.isUpdate = false;
				return this.FormMSG(33, 'Add day to timesheets');
			} else {
				this.isUpdate = true;
				return this.FormMSG(133, 'Save modifications to timesheets');
			}
		},
		async saveCurDay() {
			this.curDay.date = this.curDay.date.split('T')[0];
			let curDayDate = this.curDay.date;
			this.curDay.date = this.curDay.date.substring(0, 10);
			// console.log("CURDAY 3 during save this.curDay.strTime:",this.curDay.strTime);
			// console.log("CURDAY 3 a during save this.curDay.date:",this.curDay.date);

			let StrTime = this.curDay.strTime;
			let EndTime = this.curDay.endTime;
			// console.log('CURDAY 3 during save this.curDay.strTime:', this.curDay.strTime)
			// console.log("CURDAY 3 during save this.curDay.endTime:",this.curDay.endTime);
			if (EndTime < StrTime) {
				alert(this.FormMSG(1111, 'End time is before start time!'));
				return;
			}

			// transport from to home
			let LeftHomeAt = this.curDay.leftHomeAt;
			let ReturnedHomeAt = this.curDay.returnedHomeAt;

			if (this.curDay.transportFromHomeToWork === false) {
				// set from and to home to work start and end
				// console.log('insavecurday ressetting transport')
				LeftHomeAt = StrTime;
				ReturnedHomeAt = EndTime;
			}

			if (this.curDay.returnedHomeAt < this.curDay.endTime) {
				this.curDay.returnedHomeAt = this.curDay.endTime;
			}

			if (this.curDay.leftHomeAt > this.curDay.strTime) {
				this.curDay.leftHomeAt = this.curDay.strTime;
			}

			if (ReturnedHomeAt < LeftHomeAt) {
				alert(this.FormMSG(1112, 'Returned home time is before left home time!'));
				return;
			}

			// setting lunch to strTime if lunch perdiem or if start and stop are identical
			let LunchStrTime = this.curDay.lunchStrTime;
			let LunchEndTime = this.curDay.lunchEndTime;

			if (LunchStrTime === LunchEndTime || this.curDay.notSpecifiedLunch) {
				//    //console.log("null case for lunch")
				LunchStrTime = StrTime;
				LunchEndTime = StrTime;
			}

			if (LunchEndTime < LunchStrTime) {
				alert(this.FormMSG(1113, 'Lunch end time is before lunch start time!'));
				return;
			}

			if (this.showStageDay() && this.curDay.stageDayUsed) {
				StrTime = moment(this.curDay.date).add(this.startHourOffice, 'hours').format();
				EndTime = null;
			} else {
				this.curDay.useStageDay = false;
			}

			let Lunch = false;
			if (this.curDay.lunch == true) {
				Lunch = true;
			}

			let Hotel = false;
			if (this.curDay.hotel == true) {
				Hotel = true;
			}

			let UseDinner = false;
			if (this.curDay.useDinner == true) {
				UseDinner = true;
			}

			let UseAbroad = false;
			if (this.curDay.useAbroad == true) {
				UseAbroad = true;
			}

			let UseMileageToSet = false;
			if (this.curDay.useMileageToSet == true) {
				UseMileageToSet = true;
			}

			let stageDayUsed = this.curDay.stageDayUsed;
			if (stageDayUsed === 0 || stageDayUsed === 1) {
				if (stageDayUsed === 0) {
					stageDayUsed = false;
				} else if (stageDayUsed === 1) {
					stageDayUsed = true;
				}
			}

			const DayType = this.curDay.dayType;
			this.disableSaveButton = true;

			const NotSpecifiedLunch = this.curDay.notSpecifiedLunch;

			const TransportFromHomeToWork = this.curDay.transportFromHomeToWork;

			const StrEndNotSpecified = this.curDay.strEndNotSpecified;
			const Remark = this.curDay.remark;

			let UserId = parseInt(this.singleUserSelected);

			await this.saveLocation();

			let LocationId = parseInt(this.curDay.locationId);

			if (!this.multipleSelectionDate) {
				await this.saveCurDayForSingleSelection(
					curDayDate,
					StrTime,
					EndTime,
					LunchStrTime,
					LunchEndTime,
					Lunch,
					Hotel,
					UseDinner,
					UseAbroad,
					DayType,
					LeftHomeAt,
					ReturnedHomeAt,
					NotSpecifiedLunch,
					UseMileageToSet,
					TransportFromHomeToWork,
					UserId,
					StrEndNotSpecified,
					LocationId,
					stageDayUsed,
					Remark
				);
			} else {
				let TsDayEntryForCrewInput = [];
				if (!this.useViewPlanning) {
					if (this.isActionCrud === this.updateData) {
						if (this.curDay.user !== undefined) {
							TsDayEntryForCrewInput.push({
								userId: parseInt(this.curDay.user.id, 10),
								date: this.curDay.date
							});
						}
					}
					this.selectedUser.map((user) => {
						for (let i = 0; i < this.days.length; i++) {
							TsDayEntryForCrewInput.push({
								userId: parseInt(user.value, 10),
								date: this.days[i].id
							});
						}
					});
				} else {
					this.listUserWithDates.map((option) => {
						for (let i = 0; i < option.dates.length; i++) {
							TsDayEntryForCrewInput.push({
								userId: parseInt(option.userId, 10),
								date: option.dates[i]
							});
						}
					});
				}

				await this.saveCurDayForMultipleSelection(
					TsDayEntryForCrewInput,
					StrTime,
					EndTime,
					LunchStrTime,
					LunchEndTime,
					Lunch,
					Hotel,
					UseDinner,
					UseAbroad,
					DayType,
					LeftHomeAt,
					ReturnedHomeAt,
					NotSpecifiedLunch,
					UseMileageToSet,
					TransportFromHomeToWork,
					StrEndNotSpecified,
					LocationId
				);
			}
		},
		async saveCurDayForSingleSelection(
			curDayDate,
			StrTime,
			EndTime,
			LunchStrTime,
			LunchEndTime,
			Lunch,
			Hotel,
			UseDinner,
			UseAbroad,
			DayType,
			LeftHomeAt,
			ReturnedHomeAt,
			NotSpecifiedLunch,
			UseMileageToSet,
			TransportFromHomeToWork,
			UserId,
			StrEndNotSpecified,
			LocationId,
			UseStageDay,
			Remark
		) {
			await saveTsDay(
				curDayDate,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				UserId,
				StrEndNotSpecified,
				LocationId,
				UseStageDay,
				Remark
			)
				.then(async (result) => {
					// await this.reloadData();
					this.disableSaveButton = false;
					this.createToastForMobile(
						this.FormMSG(162, 'Success'),
						!this.isUpdate ? this.FormMSG(187, 'Workday added to Timesheet!') : this.FormMSG(188, 'Workday updated!'),
						''
					);
					this.$emit('form-time-card:save-cur-day-success', {
						...this.curDay,
						user: {
							id: UserId
						},
						currMonthStr: moment(new Date(this.curDay.date)).format('YYYY-MM'),
						currDayDate: moment(new Date(this.curDay.date)).format('YYYY-MM-DD')
					});
				})
				.catch((error) => {
					this.disableSaveButton = false;
					if (error.message.includes('no valid contract on this day')) {
						var errStr = this.FormMSG(251, 'There is no valid contract for this day');
						alert(errStr);
					} else {
						this.erreur = error;
						alert("Internal error (check network and if user's contract is valid)");
					}
				});
		},
		async saveCurDayForMultipleSelection(
			TsDayEntryForCrewInput,
			StrTime,
			EndTime,
			LunchStrTime,
			LunchEndTime,
			Lunch,
			Hotel,
			UseDinner,
			UseAbroad,
			DayType,
			LeftHomeAt,
			ReturnedHomeAt,
			NotSpecifiedLunch,
			UseMileageToSet,
			TransportFromHomeToWork,
			StrEndNotSpecified,
			LocationId
		) {
			await saveTsDaysForCrew(
				TsDayEntryForCrewInput,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				StrEndNotSpecified,
				LocationId
			)
				.then(async (result) => {
					if (result.length !== 0) {
						this.disableSaveButton = false;
						this.showPopupInfo = true;
						this.dataToPopupInfo = result;
						this.$emit('form-time-card:close-cur-day');
					} else {
						this.disableSaveButton = false;
						this.createToastForMobile(this.FormMSG(162, 'Success'), this.FormMSG(652, 'The timesheet added/updated successfully!'));
						this.$emit('form-time-card:save-cur-day-success', {
							...this.curDay,
							user: {
								id: TsDayEntryForCrewInput[0].userId
							},
							currMonthStr: moment(new Date(this.curDay.date)).format('YYYY-MM'),
							currDayDate: moment(new Date(this.curDay.date)).format('YYYY-MM-DD')
						});
					}
				})
				.catch((error) => {
					this.disableSaveButton = false;
					if (error.message.includes('No valid contract on this day')) {
						this.createToastForMobile(
							this.FormMSG(37, 'No valid contract on this day'),
							this.FormMSG(251, 'There is no valid contract for this day'),
							'',
							'danger'
						);
					} else {
						this.erreur = error;
						this.createToastForMobile(
							this.FormMSG(38, 'Error'),
							this.FormMSG(39, "Internal error (check network and if user's contract is valid)"),
							'',
							'danger'
						);
					}
				});
		},
		updateLeftReturnedTimes(newTimes) {
			this.curDay.leftHomeAt = newTimes[0];
			this.curDay.returnedHomeAt = newTimes[1];

			if (this.showTransport() && +this.curDay.id === 0) {
				if (
					new Date(this.curDay.leftHomeAt).getTime() < new Date(this.curDay.strTime).getTime() ||
					new Date(this.curDay.returnedHomeAt).getTime() > new Date(this.curDay.endTime).getTime()
				) {
					this.curDay.transportFromHomeToWork = true;
				} else {
					this.curDay.transportFromHomeToWork = false;
				}
			}
		},
		getLunchPerDiemCurDayString() {
			if (this.curDay.notSpecifiedLunch) {
				return this.FormMSG(290, 'Not Specified').toUpperCase();
			} else {
				return moment(this.curDay.lunchStrTime).format('hh[h] mm[m]') + ' - ' + moment(this.curDay.lunchEndTime).format('HH[h] mm[m]');
			}
		},
		showStageDay() {
			return store.isPme() || this.isFilmSingle || !store.state.myProject.useStageDay ? false : true;
		},
		getDayType(value) {
			const findIndex = this.optionsForDefaultDayType.findIndex((oDD) => oDD.value === value);
			const result = this.optionsForDefaultDayType[findIndex];
			return result ? result.text.split('(')[0] : '';
		},
		tooltipContentPerIdem(item) {
			let content = [];

			if (this.showHotelPerDiem() && item.hotel && item.contract.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.getCurrency(item.contract.hotelPerDiem));
			}

			if (this.showLunchPerDiem() && item.lunch && item.contract.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.getCurrency(item.contract.lunchPerDiem));
			}

			if (this.showDinnerPerDiem() && item.useDinner && item.contract.dinnerPerDiem > 0) {
				content.push(this.FormMSG(212, 'Dinner: ') + this.getCurrency(item.contract.dinnerPerDiem));
			}

			if (this.showAbroadPerDiem() && item.useAbroad && item.contract.abroadPerDiem > 0) {
				content.push(this.FormMSG(211, 'Abroad: ') + this.getCurrency(item.contract.abroadPerDiem));
			}

			return content.join('<br /> ');
		},
		showTransport() {
			//console.log("in show transport:",store.state.myProject);
			return store.state.myProject.useHomeToWorkTime;
		},
		showLunch() {
			return store.state.myProject.useLunchTime;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem && !this.curDay.stageDayUsed;
		},
		getHours(val) {
			return val.split('T')[1].substring(0, 5);
		},
		rendDate(value) {
			return moment(value).format('DD/MM/YYYY');
		},
		getCO2(val) {
			return rendKgCo2(val);
		},
		getCurrency(val) {
			return rendCurrency(val);
		},
		getHhMmString(value) {
			let splitValue = value.split(':');
			if (parseInt(splitValue[0], 10) < 10) {
				splitValue[0] = '0' + parseInt(splitValue[0]);
			}
			if (parseInt(splitValue[1], 10) < 10) {
				splitValue[1] = '0' + parseInt(splitValue[1]);
			}
			return `${splitValue[0]}h ${splitValue[1]}m`;
		},
		updateStrEndTimes(newTimes) {
			let strTime = moment(newTimes[0]).utc().format();
			let endTime = moment(newTimes[1]).utc().format();
			this.curDay.strTime = strTime;
			this.curDay.endTime = endTime;

			if (!this.valueCurDayFromDb) {
				this.curDay.returnedHomeAt = this.curDay.endTime;
			}

			if (!this.valueCurDayFromDb) {
				this.curDay.leftHomeAt = this.curDay.strTime;
			}

			this.valueCurDayFromDb = false;
		},
		updateLunchTimes(newTimes) {
			let lunchStrTime = moment(newTimes[0]).utc().format();
			let lunchEndTime = moment(newTimes[1]).utc().format();
			this.curDay.lunchStrTime = lunchStrTime;
			this.curDay.lunchEndTime = lunchEndTime;
		},
		async calPageUpdated(item) {
			const { month, year } = item;
			let mon = parseInt(month, 10);
			if (mon < 10) {
				mon = '0' + mon;
			}

			// var str = parseInt(item.year, 10) + '-' + mon + '-' + '01'
			let str = parseInt(year, 10) + '-' + moment().format('MM') + '-' + moment().format('DD');
			if (mon !== moment().format('MM')) {
				str = parseInt(year, 10) + '-' + mon + '-' + '01';
			}

			this.currentMonth = moment(str).format('MMMM');
			if (this.screenUsedTheForm === this.isTimeCard && this.lazyDisplayingElForEntry === true) {
				this.isAvailableDate(this.allAvailableDates, str);
				if (this.allowTimesheetEntryOutOfContract === false && this.curDay.isAvailable === false) {
					this.selectedDateFromMonth = true;
				} else {
					this.selectedDateFromMonth = false;
				}
			}

			const selectedDateString = str + 'T00:00:00Z';

			const currentStageDay = await this.getStageDay(selectedDateString);
			this.hasAvailableStageDay = !!currentStageDay;
			this.updateDate(str);
		},
		async calDateSelected(payload, haveTZ = false) {
			let selectedDateString = '';
			if (haveTZ) {
				selectedDateString = payload;
			} else {
				selectedDateString = payload.id + 'T00:00:00Z';
			}

			const currentStageDay = await this.getStageDay(selectedDateString);
			this.hasAvailableStageDay = !!currentStageDay;

			// calendar-work case only
			if (!_.isNil(payload.isCalendarWork) && payload.isCalendarWork === true) {
				this.isAvailableDate(this.allAvailableDates, payload.id);
			}
			// calendar-work case only

			if (!_.isNil(payload.isDisabled) && payload.isDisabled === false) {
				this.updateDate(payload.id);
			} else {
				if (
					this.allowTimesheetEntryOutOfContract === false &&
					this.multipleSelectionDate === false &&
					_.isObject(payload.isDisabled) &&
					_.isNil(payload.isCalendarWork)
				) {
					this.$emit('form-time-card:disabled', payload);
					this.showWarningOutOfContractDate = true;
					this.selectedDateFromMonth = true;
				} else if (
					this.allowTimesheetEntryOutOfContract === false &&
					this.multipleSelectionDate === false &&
					this.isAvailable === false &&
					!_.isNil(payload.isCalendarWork)
				) {
					this.$emit('form-time-card:disabled', payload);
					this.selectedDateFromMonth = true;
				} else {
					if (haveTZ) {
						this.updateDate(moment(payload).format('YYYY-MM-DD'));
					} else {
						this.updateDate(payload.id);
					}
				}
			}
			this.setContractForDay(payload.date);
		},
		isAvailableDate(availableDates, date) {
			const currentDate = new Date(moment(date).format('YYYY-MM-DD')).getTime();
			let isAvailable = false;
			for (let i = 0; i < availableDates.length; i++) {
				const start = new Date(availableDates[i].start).getTime();
				const end = new Date(availableDates[i].end).getTime();
				if (start <= currentDate && end >= currentDate) {
					isAvailable = true;
					break;
				}
			}

			this.isAvailable = this.curDay.isAvailable = isAvailable;
		},
		setContractForDay(date) {
			//console.log("in set contractForDay this.myContracts:",this.myContracts);
			if (!isNil(this.myContracts)) {
				for (let i = 0; i < this.myContracts.length; i++) {
					//console.log("in set contractForDay date:",date);
					//console.log("in set contractForDay date:",this.myContracts[i].startDate);
					if (date >= this.myContracts[i].startDate.substring(0, 10) && date <= this.myContracts[i].endDate.substring(0, 10)) {
						//console.log("in set contractForDay contract found:",this.myContracts[i]);
						this.contract = this.myContracts[i];

						return;
					}
				}
			}
			// console.log("in set contractForDay contract found:",this.myContracts[0]);
			if (!isNil(this.myContracts) && this.myContracts[0]) {
				this.contract = this.myContracts[0];
			}
		},
		selectCurDay() {
			let foundCurDayInArray = false;

			for (let i = 0; i < this.TsDays.length; i++) {
				let temp = this.TsDays[i].date;
				temp = temp.substring(0, 10);
				if (temp == this.curDay.date) {
					this.curDay = { ...this.TsDays[i] };

					if (this.curDay.stageDayUsed === 1) {
						this.curDay.stageDayUsed = true;
					}

					foundCurDayInArray = true;
					this.newDaySelected = false;
					if (this.screenUsedTheForm === 'time-card') {
						this.setContractForDay(this.TsDays[i].date);
					}
					this.originalCurDay = { ...this.curDay };
					// this.checkSpecifyAtHome(this.TsDays[i]);

					this.valueCurDayFromDb = true;

					break;
				}
			}

			if (!foundCurDayInArray) {
				const tmpStr = this.curDay.date;
				this.newCurDay.validatedStatus = this.validatedText(this.newCurDay.validated);
				this.curDay = this.newCurDay;
				this.curDay.remark = '';
				this.curDay.date = tmpStr;
				this.initializeCurDay(this.curDay.date);

				if (!isNil(this.myContracts) && this.myContracts.length > 0) {
					this.curDay.dayType = parseInt(this.contract.defaultDayType, 10);
				}
				this.newDaySelected = true;
				this.originalCurDay = {};
			}
		},
		initializeCurDay(checkDate) {
			let date = checkDate.split`T00:00:00Z`[0];
			if (this.screenUsedTheForm === this.isEntryForCrew) {
				if (this.timeCardToEdit !== null) {
					this.curDay = { ...this.curDay, ...this.timeCardToEdit };
				} else {
					this.curDay.strTime = moment(date).add(this.startHourOffice, 'hours').format();
					this.curDay.endTime = moment(date).add(this.endHourOffice, 'hours').format();
					this.curDay.leftHomeAt = moment(date).add(this.startHourOffice, 'hours').format();
					this.curDay.returnedHomeAt = moment(date).add(this.startHourOffice, 'hours').format();
					this.curDay.lunchStrTime = moment(date).add(this.startLunchOffice, 'hours').format();
					this.curDay.lunchEndTime = moment(date).add(this.endLunchOffice, 'hours').format();
					this.curDay.useStageDay = false;
				}
			} else {
				this.setContractForDay(date);

				this.curDay.strTime = moment(date).add(this.startHourOffice, 'hours').format();
				this.curDay.endTime = this.initEndTimeByContract(date);
				this.curDay.leftHomeAt = moment(date).add(this.startHourOffice, 'hours').format();
				this.curDay.returnedHomeAt = this.initEndTimeByContract(date);
				this.curDay.lunchStrTime = moment(date).add(this.startLunchOffice, 'hours').format();
				this.curDay.lunchEndTime = moment(date)
					.add(this.startLunchOffice * 60 + this.contract.lunchMinimum, 'minutes')
					.format();
				this.curDay.useStageDay = false;
			}
		},
		dailyWorkCalculated() {
			return this.contract.totMinPerDay + this.contract.lunchMinimum;
		},
		/**
		 * This function calculates the end time of a workday based on the given date and contract information.
		 * @param {string} date - The date string in the format 'YYYY-MM-DD'.
		 * @returns {string} - The ISO-formatted date-time string representing the end time of the workday.
		 *
		 * The function works as follows:
		 * 1. It creates a moment object from the input date and sets the time to the start of the day (00:00:00).
		 * 2. It adds 8 hours to the start of the day to set the initial working time to 08:00:00.
		 * 3. It adds the total minutes per day and lunch minutes specified in the contract to calculate the end time of the workday.
		 * 4. It returns the end time as an ISO-formatted date-time string.
		 */
		initEndTimeByContract(date) {
			const currStrTime = moment(new Date(date)).startOf('day').add(this.startHourOffice, 'hours');

			return currStrTime.add(this.dailyWorkCalculated(), 'minutes').toISOString();
		},
		async updateDate($event) {
			// suspend link between tsdays and curday until we check if new one or existing one
			this.curDay = this.newCurDay;

			// receiving 2019-02-24
			this.curDay.date = $event;
			this.otherLocation = '';

			// check if month changed
			const curMStemp = $event.substring(0, 7);

			if (this.curMonthStr === curMStemp) {
				// in same month select day
				this.selectCurDay();
			} else {
				// need to load new month
				//console.log("need to reload:" ,curMStemp);
				this.curMonthStr = curMStemp;
				const regExp = /^(\d{4}\-\d{2}\-\d{2})/;

				this.curDay.endTime = this.curDay.endTime.replace(regExp, $event);
				this.curDay.strTime = this.curDay.strTime.replace(regExp, $event);
				if (this.curDay.transportFromHomeToWork) {
					this.curDay.leftHomeAt = this.curDay.leftHomeAt.replace(regExp, $event);
					this.curDay.returnedHomeAt = this.curDay.returnedHomeAt.replace(regExp, $event);
				}

				// await this.reloadData();
				this.$emit('form-time-card:udpate-date', {
					currMonthStr: moment(new Date($event)).format('YYYY-MM'),
					currDayDate: moment(new Date($event)).format('YYYY-MM-DD')
				});
			}
		},
		handleClickToday() {
			const date = new Date();
			let mon = parseInt(date.getMonth(), 10) + 1;
			let day = parseInt(date.getDate(), 10);
			if (mon < 10) {
				mon = '0' + mon;
			}
			if (day < 10) {
				day = '0' + day;
			}
			let str = date.getFullYear() + '-' + mon + '-' + day;
			////console.log("calPageUpdated:", str);
			this.updateDate(str);
			// this.selectedDayByCalendar = str;
			this.setContractForDay(str);
		},
		changeActiveView(type) {
			this.activeView = type;
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.getCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.getCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(115, 'Phone ') + '(' + this.rendAllowanceType(item.contract.phoneAllowanceRate) + '): ' + this.getCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.getCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.getCurrency(item.boxKitAllowance)
				);
			}

			if (item.mileageToSetAllowance > 0) {
				content.push(`${this.FormMSG(129, 'Mileage to set ')} : ${this.getCurrency(item.mileageToSetAllowance)}`);
			}

			return content.join('<br /> ');
		},
		handleClickAccordion(type) {
			if (this.accordionActive === type) {
				this.accordionActive = null;
			} else {
				this.accordionActive = type;
			}
		},
		resetData() {
			this.erreur = '';
			this.curDay = {
				id: 0,
				date: moment().format('YYYY-MM-DD') + 'T00:00:00Z',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: false,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false,
				strEndNotSpecified: false,
				locationId: 0
			};
			this.newCurDay = {
				id: 0,
				date: '2019-09-01',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				leftHomeAt: 'T07:00:00Z',
				returnedHomeAt: 'T19:00:00Z',
				totMin: 0,
				notSpecifiedLunch: true,
				lunchStrTime: 'T12:00:00Z',
				lunchEndTime: 'T13:00:00Z',
				lunch: false,
				hotel: false,
				useDinner: false,
				useAbroad: false,
				stageDayUsed: false,
				dayType: 0,
				validated: 0,
				kgCoTwo: 0,
				validatedStatus: 'Not Submited',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				statusClass: 'not-submitted',
				useMileageToSet: false,
				transportFromHomeToWork: false,
				strEndNotSpecified: false,
				locationId: 0
			};
			this.activeView = 'day';
			this.curMonthStr = `${moment().format('YYYY')}-${moment().format('MM')}`;
			this.TsDays = [];
			this.newDaySelected = false;
			this.originalCurDay = {};
			this.myContracts = [];
			this.contract = {
				defaultDayType: 0,
				hotelPerDiem: 0,
				lunchPerDiem: 0,
				lunchMinimum: 60,
				totMinPerDay: 480 // DEFAULT 8 HOUR WORK
			};
			this.valueCurDayFromDb = false;
			this.accordionActive = null;
			this.disableSaveButton = false;
			this.successModal = false;
			this.successModalDelete = false;

			this.userList = [];
			this.selectedUser = [];
			this.singleUserSelected = null;
			this.department = '';
			this.allDepartements = [];
			this.validationDepartments = [];

			this.days = [];
			this.locations = [];

			this.listUserWithDates = [];
		},
		reverseDate(date) {
			return date.split('-').reverse().join('-');
		},
		closePopup() {
			this.showPopupInfo = false;
			this.handleFormTimeCardSubmitSuccess();
		},
		formatedDate(date) {
			if (date !== undefined) {
				const langCurrent = this.currentLang;
				let data = moment(date).locale`${langCurrent}`.format('dddd MMMM DD, YYYY').split` `;
				let text = data.slice(0, 2).map((text) => text.charAt(0).toUpperCase() + text.substr(1)).join` ` + ` ` + data.slice(2).join` `;
				return text;
			}
		},
		async getStageDay(date) {
			return await getStageDay(date).catch((error) => {
				console.error({ error });
			});
		},
		async availableDates() {
			return await new Promise((resolve) => {
				let availableDates = [];
				const constracts = store.state.myContracts;
				for (let i = 0; i < constracts.length; i++) {
					availableDates.push({
						start: new moment(constracts[i].startDate).utc().format('YYYY-MM-DD'),
						end: new moment(constracts[i].endDate).utc().format('YYYY-MM-DD')
					});
				}
				resolve(availableDates);
			});
		}
	}
};
</script>

<style scoped></style>
